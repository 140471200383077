


























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ChangeLogModal extends Vue {
  @Prop() changeLog: any
}
